import React, { useState } from 'react';
import { Address } from '@ton/core';
import { Door } from '@phosphor-icons/react';
import { useTonConnectUI, useTonWallet } from '@tonconnect/ui-react';
import Locales from '../locale';

function Payout({ colorKit, resources, setCurrentPage }) {
  const wallet = useTonWallet();
  const [tonConnectUI] = useTonConnectUI();

  const [silverSpend, setSilverSpend] = useState(1000000000);
  const [goldSpend, setGoldSpend] = useState(100);
  const [totalTON, setTotalTON] = useState(0.1);

  function linkTONWallet() {
    tonConnectUI.openModal();
  }

  function unlinkTONWallet() {
    tonConnectUI.disconnect();
  }

  function isWalletLinked() {
    return wallet != null;
  }

  function proceedToPayout() {

  }

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const handleSilverChange = (e) => {
    if (e.target.value < 1000000000) {
      e.target.value = 1000000000;
    }

    const silver = Math.max(Number(e.target.value), 1000000000);
    setSilverSpend(silver);

    const gold = Math.floor(silver / 1000000000) * 100;
    setGoldSpend(gold);

    const ton = parseFloat(((gold / 100) * 0.1).toFixed(4));
    setTotalTON(ton);
  };

  const handleGoldChange = (e) => {
    if (e.target.value < 100) {
      e.target.value = 100;
    }

    const gold = Math.floor(Number(e.target.value));
    setGoldSpend(gold);

    const silver = Math.floor(gold / 100) * 1000000000;
    setSilverSpend(silver);

    const ton = parseFloat(((gold / 100) * 0.1).toFixed(4));
    setTotalTON(ton);
  };

  const handleTonChange = (e) => {
    if (e.target.value < 0.1) {
      e.target.value = 0.1;
    }

    const ton = parseFloat(Number(e.target.value).toFixed(4));
    setTotalTON(ton);

    const gold = Math.floor((ton / 0.1) * 100);
    setGoldSpend(gold);

    const silver = Math.floor(gold / 100) * 1000000000;
    setSilverSpend(silver);
  };

  const isSilverEnough = silverSpend <= (resources.silver || 0);
  const isGoldEnough = goldSpend <= (resources.gold || 0);

  return (
    <div className="page-container">
      <h1 className="text-3xl jost-bold mb-4 mt-1">{Locales.localize("payout_title")}</h1>
      <div className="payout-section overflow-auto h-[calc(100vh-6rem)]" style={{ paddingBottom: '14rem' }}>
        <div className="calculator-section">
          <div className="mb-4 text-left">
            <label className="block text-lg mb-2 jost-bold">{Locales.localize("payout_silver_you_spend")}:</label>
            <input
              type="number"
              value={silverSpend}
              onChange={handleSilverChange}
              onBlur={handleScrollToTop}
              min={1000000000}
              className={`w-full border bg-black px-4 py-2 rounded ${isSilverEnough ? 'border-gray-300' : 'border-red-500'}`}
            />
            <p className="mt-2 text-sm">{Locales.localize("payout_silver_balance")}: {resources.silver || 0}</p>
            {!isSilverEnough && (
              <p className="text-red-500 text-sm">{Locales.localize("payout_insufficient_silver")}</p>
            )}
          </div>
          <div className="mb-4 text-left">
            <label className="block text-lg mb-2 jost-bold">{Locales.localize("payout_gold_you_spend")}:</label>
            <input
              type="number"
              value={goldSpend}
              onChange={handleGoldChange}
              onBlur={handleScrollToTop}
              min={100}
              step={1}
              className={`w-full border bg-black px-4 py-2 rounded ${isGoldEnough ? 'border-gray-300' : 'border-red-500'}`}
            />
            <p className="mt-2 text-sm">{Locales.localize("payout_gold_balance")}: {resources.gold || 0}</p>
            {!isGoldEnough && (
              <p className="text-red-500 text-sm">{Locales.localize("payout_insufficient_gold")}</p>
            )}
          </div>
          <div className="mb-4 text-left">
            <label className="block text-lg mb-2 jost-bold">{Locales.localize("payout_total_ton")}:</label>
            <input
              type="number"
              value={totalTON}
              onChange={handleTonChange}
              onBlur={handleScrollToTop}
              min={0.1}
              step={0.1}
              className="w-full border bg-black border-gray-300 px-4 py-2 rounded"
            />
          </div>
          <button
            style={{ backgroundColor: colorKit.accent }}
            className={`invite-button w-full text-white font-semibold py-2 px-4 rounded jost-regular ${
              !isSilverEnough || !isGoldEnough ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            disabled={!isSilverEnough || !isGoldEnough || !isWalletLinked()}
            onClick={() => proceedToPayout()}
          >
            {Locales.localize("payout_proceed_button")}
          </button>
        </div>
        <p className="text-sm" style={{ opacity: 0.625, marginTop: "12px" }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <p className="text-sm jost-bold" onClick={() => isWalletLinked() ? false : linkTONWallet()} style={{ cursor: 'pointer' }}>
              {!isWalletLinked() ? Locales.localize("payout_link_wallet_label") : Locales.localize("payout_wallet_label") + ": ..." + Address.parse(wallet.account.address).toString({ urlSafe: true, bounceable: false }).slice(-12) }
            </p>
            {isWalletLinked() && <Door size={20} weight="bold" style={{ marginLeft: '12px', cursor: 'pointer' }} onClick={() => unlinkTONWallet()} />}
          </div>
        </p>
        <div className="conversion-rates-section mt-8 p-4 jost-regular" style={{ marginTop: "16px" }}>
          <p>
            {Locales.localize("payout_calculation_rules")}
          </p>
          <p className="text-lg" style={{ opacity: 0.625, marginTop: "14px" }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <p className="text-lg jost-bold" onClick={() => setCurrentPage('about')}>
                {Locales.localize("payout_how_to_acquire_gold")}
              </p>
            </div>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Payout;
