import React from 'react';
import NavItem from './NavItem';
import Locales from '../locale';
import { useSpring, animated } from '@react-spring/web';
import { House, ListChecks, ShoppingCart, UsersThree, Trophy } from '@phosphor-icons/react';

function BottomNavbar({ 
  currentPage, setCurrentPage, colorKit, 
  setRefreshRequired, setGiftTargetUser, setDisguisedUser 
}) {
  const { backgroundColor } = useSpring({
    backgroundColor: !["home"].includes(currentPage)
      ? 'rgba(6, 6, 10, 100%)'
      : 'rgba(0, 0, 0, 0.5%)',
    config: { duration: 400 },
  });

  return (
    <animated.nav
      className="fixed bottom-0 left-0 right-0"
      style={{
        paddingBottom: "4vh",
        backgroundColor,
      }}
    >
      <div className="flex justify-around items-center py-2 jost-regular">
        <NavItem
          onClick={() => {
            setCurrentPage('home');
            setDisguisedUser(null);
            setRefreshRequired(true);
          }}
          icon={<House size={24} weight="bold" />}
          colorKit={colorKit}
          label={Locales.localize("navbar_home")}
          isActive={currentPage === 'home'}
        />
        <NavItem
          onClick={() => {
            setDisguisedUser(null);
            setRefreshRequired(true);
            setCurrentPage('leaderboard');
          }}
          icon={<Trophy size={24} weight="bold" />}
          colorKit={colorKit}
          label={Locales.localize("navbar_leaderboard")}
          isActive={currentPage === 'leaderboard'}
        />
        <NavItem
            onClick={() => {
              setDisguisedUser(null);
              setRefreshRequired(true);
              setCurrentPage('tasks');
            }}
            icon={<ListChecks size={24} weight="bold" />}
            colorKit={colorKit}
            label={Locales.localize("navbar_tasks")}
            isActive={currentPage === 'tasks'}
          />
        <NavItem
          onClick={() => {
            setDisguisedUser(null);
            setGiftTargetUser(null);
            setRefreshRequired(true);
            setCurrentPage('shop');
          }}
          icon={<ShoppingCart size={24} weight="bold" />}
          colorKit={colorKit}
          label={Locales.localize("navbar_shop")}
          isActive={currentPage === 'shop'}
        />
        <NavItem
          onClick={() => {
            setDisguisedUser(null);
            setRefreshRequired(true);
            setCurrentPage('frens');
          }}
          icon={<UsersThree size={24} weight="bold" />}
          colorKit={colorKit}
          label={Locales.localize("navbar_frens")}
          isActive={currentPage === 'frens'}
        />
      </div>
    </animated.nav>
  );
}

export default BottomNavbar;
