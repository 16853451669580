import React, { useEffect, useState, useRef } from 'react';
import { useSprings, useSpring, animated } from "@react-spring/web";
import { getDashboard, buyAChick } from '../api/client';
import { Info, Wallet } from '@phosphor-icons/react';
import { EmojiProvider, Emoji } from "react-apple-emojis";
import emojiData from "react-apple-emojis/src/data.json";
import colorKits, { silver } from "../colors";
import Locales from '../locale';

var isFetchLocked = false;
var efficiencyDecay = 0.85;

function calculateReward(baseReward, numBirds) {
  if (numBirds === 0) {
    return 0;
  }

  let totalReward = baseReward;
  let accumulatedSum = 1.0;

  for (let i = 2; i <= numBirds; i++) {
    let decayFactor = Math.pow(i, efficiencyDecay);
    accumulatedSum += 1.0 / decayFactor;
  }

  totalReward *= accumulatedSum;
  return totalReward;
}

function Home({ 
  resources, setResources, telegramInitData, 
  colorKit, setColorKit, refreshRequired, 
  setRefreshRequired, setVignetteShimming,
  setCurrentPage, disguisedUser }) {
  const colors = ["#2196F3", "#4CAF50", "#FFEB3B", "#FB8C00", "#F44336"];
  const birdPrices = [100, 100000, 500000, 10000000, 1000000000];
  const silverValues = [20, 100, 250, 500, 1000];
  const previousBalance = useRef(resources.silver);

  const [isBuyLocked, setIsBuyLocked] = useState(false);
  const [bottomAlertShown, setBottomAlertShown] = useState(false);
  const [bottomAlertTimeout, setBottomAlertTimeout] = useState(false);
  const [currentPerformance, setCurrentPerformance] = useState(0);
  const [bottomAlertText, setBottomAlertText] = useState("");
  const [x2Multiplier, setX2Multiplier] = useState(1);
  const [intervalId, setIntervalId] = useState(null);
  const [refreshIntervalId, setRefreshIntervalId] = useState(null);
  const [pressTimeoutId, setPressTimeoutId] = useState(null);

  const handlePressStart = (index) => {
    if (!isBuyLocked) {
      onBirdClick(index);
    }
  
    const id = setTimeout(() => {
      if (!isBuyLocked) {
        const intervalId = setInterval(() => {
          onBirdClick(index);
        }, 430);
        setIntervalId(intervalId);
      }
    }, 310);
    setPressTimeoutId(id);
  };
  
  const handlePressEnd = () => {
    if (pressTimeoutId) {
      clearTimeout(pressTimeoutId);
    }
  
    if (intervalId) {
      clearInterval(intervalId);
    }
  };

  const onBirdClick = async (index) => {
    var buyAmount = 1;

    if (disguisedUser) {
      return;
    }

    if (isBuyLocked) {
      return;
    }

    if (resources.birds[index] > 1000) {
      buyAmount = 100;
    } else if (resources.birds[index] > 100) {
      buyAmount = 10;
    }

    if (resources.silver < birdPrices[index] * buyAmount) {
      if (resources.silver < birdPrices[index]) {
        pushBottomAlert(formatNumberWithDots(`Costs ${birdPrices[index]} silver\nBasic Gen ${silverValues[index]} silv/sec`));
        return;
      }

      buyAmount = 1;
    }

    setIsBuyLocked(true);

    window.Telegram.WebApp.HapticFeedback
      .impactOccurred("soft");

    setTimeout(() => {
      window.Telegram.WebApp.HapticFeedback
        .impactOccurred("medium");
    }, 250);

    try {
        setResources((prevResources) => {
            const newBirds = [...prevResources.birds];
            newBirds[index] += buyAmount;
            return {
              ...prevResources,
              birds: newBirds,
              silver: prevResources.silver - birdPrices[index],
            };
        });

        setTimeout(() => {
          setIsBuyLocked(false);
        }, 420);

        const response = await new Promise(resolve => {
          setTimeout(async () => {
            resolve(await buyAChick(telegramInitData, index, buyAmount));
          }, 10);
        });

        if (response != null) {
            if (response.silver < birdPrices[index] * buyAmount) {
              setIsBuyLocked(true);

              if (pressTimeoutId) {
                clearTimeout(pressTimeoutId);
              }
            
              if (intervalId) {
                clearInterval(intervalId);
              }
            }

            setResources(() => {
              return {
                birds: response.birds,
                silver: response.silver
              };
            });

            setCurrentPerformance(calculatePerformance());
        } else {
          setIsBuyLocked(false);
        }
    } catch (e) {
      setIsBuyLocked(false);
    }
  };
  
  const [springs, api] = useSpring(() => ({
    animatedBalance: resources.silver,
    config: { tension: 170, friction: 26 },
  }));

  const pushBottomAlert = (text, duration = 3000) => {
    if (bottomAlertShown) {
      clearTimeout(bottomAlertTimeout);
    }

    setBottomAlertShown(true);
    setBottomAlertText(text);
    setBottomAlertTimeout(setTimeout(() => 
      setBottomAlertShown(false), duration));
  };

  const calculatePerformance = () => {
    let performance = 0;
    for (let i = 0; i < resources.birds.length; i++) {
      performance += Math.round(calculateReward(silverValues[i], resources.birds[i]));
    }

    return performance;
  };

  (async () => {
    if ((resources.silver === 0 && !isFetchLocked) || refreshRequired) {
        window.scrollTo(0, 0);

        isFetchLocked = true;
        setRefreshRequired(false);
        setTimeout(() => {
          isFetchLocked = false;
        }, 1000);

        const dashboard = await getDashboard(telegramInitData, disguisedUser ? disguisedUser.id : null);
        const { birds, silver, gold, x2_booster_ends, x10_booster_ends } = dashboard 
          || { birds: [0, 0, 0, 0, 0], silver: 0, x2_booster_ends: null };

        if (dashboard && dashboard.color) {
          setColorKit(colorKits[dashboard.color]);
        } else if (dashboard) {
          setColorKit(colorKits.blue);
        }

        const dateNow = new Date();
        const x10Date = new Date(x10_booster_ends);
        const x2Date = new Date(x2_booster_ends);

        if (x10Date > dateNow && x2Date > dateNow) {
          setVignetteShimming(2);
          setX2Multiplier(12);
        } else if (x2Date > dateNow) {
          setVignetteShimming(1);
          setX2Multiplier(2);
        } else if (x10Date > dateNow) {
          setVignetteShimming(4);
          setX2Multiplier(10);
        } else {
          setVignetteShimming(0);
          setX2Multiplier(1);
        }

        setResources(() => {
          return {
            gold,
            birds,
            silver,
          };
        });
    }
  })();

  useEffect(() => {
    setCurrentPerformance(calculatePerformance());
  }, [resources]);

  useEffect(() => {
    if (resources.silver !== previousBalance.current) {
      api.start({ animatedBalance: resources.silver });
    }
    previousBalance.current = resources.silver;
  }, [resources.silver, api]);

  const [cardSprings, cardApi] = useSprings(resources.birds.length, () => ({
    scale: 1,
    config: { tension: 170, friction: 26 },
  }));

  const refreshRef = useRef();

  useEffect(() => {
    refreshRef.current = () => {
      setRefreshRequired(true);
    };
  });
  
  useEffect(() => {
    setRefreshIntervalId(setInterval(() => {
      refreshRef.current();
    }, 10000));
  
    return () => {
      clearInterval(refreshIntervalId);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setResources((prevResources) => {
        let additionalSilver = 0;
        prevResources.birds.forEach((count, index) => {
          if (count > 0) {
            additionalSilver += Math.round((calculateReward(silverValues[index], 
              count) * x2Multiplier) / 2); // делим на 2 так как лупит 2 раза в секунду (для плавности)
            cardApi.start((i) => {
              if (i === index) {
                return {
                  scale: 1.075,
                  onRest: () => {
                    cardApi.start((j) => (j === index ? { scale: 1 } : {}));
                  },
                };
              }
              return {};
            });
          }
        });
        return {
          ...prevResources,
          silver: prevResources.silver + additionalSilver,
        };
      });
    }, 500);

    return () => clearInterval(interval);
  }, [cardApi, x2Multiplier]);

  function getFontSizeStyle(text) {
    text = String(text);
    return {
      fontSize: text.length > 4 ? "70%" : "100%"
    };
  }

  function formatNumberWithDots(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  return (
    <div className="flex flex-col items-center page-container mt-1" style={{ paddingBottom: '2rem' }}>
      <EmojiProvider data={emojiData}>
        {!disguisedUser && <div
          className="fixed left-5 inline-flex items-center justify-center rounded-full w-8 h-8 cursor-pointer shadow-md"
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          }}
        >
          <Wallet size={24} color="#ffffff" style={{ opacity: 0.5 }} onClick={() => setCurrentPage('payout')} />
        </div>}
        {!disguisedUser && <div
          className="fixed right-5 inline-flex items-center justify-center rounded-full w-8 h-8 cursor-pointer shadow-md"
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          }}
        >
          <Info size={24} color="#ffffff" style={{ opacity: 0.5 }} onClick={() => setCurrentPage('about')} />
        </div>}
          <h1 className={(disguisedUser ? "text-xl" : "text-3xl") + " jost-bold font-bold mb-4"}
            onClick={() => {
              if (disguisedUser) {
                if (disguisedUser.username.startsWith('@')) {
                  window.Telegram.WebApp.openTelegramLink("https://t.me/" + disguisedUser.username);
                }
              }
            }}>{disguisedUser ? `${Locales.localize("main_title_disguise_pre")} ${disguisedUser.username}${
              Locales.localize("main_title_disguise_post")}` : Locales.localize("main_title")}</h1>
          <div className="flex justify-center items-center space-x-8 mb-4">
            {resources.birds.slice(0, 3).map((count, index) => (
              <div key={index} className="flex flex-col items-center">
                <animated.div
                  style={{
                    backgroundColor: colors[index],
                    transform: cardSprings[index].scale.to((s) => `scale(${s})`),
                    boxShadow: "5px 5px 10px " + colorKit.shadow
                  }}
                  className="w-16 h-16 rounded-full mb-2 flex items-center justify-center cursor-pointer"
                  onMouseDown={() => handlePressStart(index)}
                  onMouseUp={handlePressEnd}
                  onMouseLeave={handlePressEnd}
                  onTouchStart={() => handlePressStart(index)}
                  onTouchEnd={handlePressEnd}
                >
                  <span role="img" aria-label="chicken" style={{ fontSize: "3em" }}>
                    <Emoji name="chicken" style={{ width: "1em", height: "1em" }} />
                  </span>
                </animated.div>
                <p className="text-lg jost-bold" style={getFontSizeStyle(count)}>
                  {count}
                </p>
              </div>
            ))}
          </div>

          <div className="flex justify-center items-center space-x-8 mb-4">
            {resources.birds.slice(3).map((count, index) => (
              <div key={index + 3} className="flex flex-col items-center">
                <animated.div
                  style={{
                    backgroundColor: colors[index + 3],
                    transform: cardSprings[index + 3].scale.to((s) => `scale(${s})`),
                    boxShadow: "5px 5px 10px " + colorKit.shadow
                  }}
                  className="w-16 h-16 rounded-full mb-2 flex items-center justify-center cursor-pointer"
                  onMouseDown={() => handlePressStart(index + 3)}
                  onMouseUp={handlePressEnd}
                  onMouseLeave={handlePressEnd}
                  onTouchStart={() => handlePressStart(index + 3)}
                  onTouchEnd={handlePressEnd}
                >
                  <span role="img" aria-label="chicken" style={{ fontSize: "3em" }}>
                    <Emoji name="chicken" style={{ width: "1em", height: "1em" }} />
                  </span>
                </animated.div>
                <p className="text-lg jost-bold" style={getFontSizeStyle(count)}>
                  {count}
                </p>
              </div>
            ))}
          </div>
          <div className="mb-4" style={{ marginTop: "4vh" }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <p className="text-lg font-bold" style={{ opacity: 0.575 }}>
                {Locales.localize("silver_balance")}:{' '}
                <animated.span>
                {springs.animatedBalance.to((n) =>
                  Math.round(Math.max(0, n)).toLocaleString('en').replace(/,/g, ' ')
                )}
                </animated.span>{' '}
                <Emoji
                  name="egg"
                  className="ml-0.5"
                  style={{
                    width: "0.95em",
                    height: "0.95em",
                    display: "inline-block",
                    verticalAlign: "-0.1em"
                  }}
                />
              </p>
          </div>
          <p className="text-lg font-bold" style={{ opacity: 0.625 }}>  
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <p className="text-lg font-bold chicks-balance-gradient">
                    {Locales.localize("performance")}: {currentPerformance} {Locales.localize("performance_silver_per_sec")} 
                    {x2Multiplier > 1 ? " x" + x2Multiplier : ""}
                  </p>
              </div>
          </p>
          {false && <p className="text-lg font-bold chicks-balance-gradient" style={{ opacity: 0.625 }}>  
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {!disguisedUser && <p className="text-lg font-bold" style={{ color: colorKit.accent }}
                    onClick={() => setCurrentPage('payout')}>
                      Wallet »
                  </p>}
              </div>
          </p>}
        </div>
        {bottomAlertShown && (
          <div className="fixed bottom-1 right-4 w-11/12 max-w-md flex justify-center transition-opacity duration-500 ease-in-out"
              style={{ opacity: 0.9, zIndex: 50, marginBottom: "-2vh" }}>
            <p className="bg-gray-800 text-white text-center py-3 px-5 rounded-lg shadow-lg jost-regular chicks-alert"
              style={{ whiteSpace: "pre-line" }}>
              {bottomAlertText}
            </p>
          </div>
        )}
      </EmojiProvider>
    </div>
  );
}

export default Home;
